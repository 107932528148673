export enum AffiliateProductPlacement {
  BLOG = 'BLOG',
  CREATOR_SUCCESS_EMAIL = 'CREATOR_SUCCESS_EMAIL',
  CREATOR_SUCCESS_POPUP = 'CREATOR_SUCCESS_POPUP',
  ATTENDEES_SUCCESS_EMAIL = 'ATTENDEES_SUCCESS_EMAIL',
  ATTENDEES_SUCCESS_POPUP = 'ATTENDEES_SUCCESS_POPUP',
  PRODUCTION_SECTION = 'PRODUCTION_SECTION',
}

export namespace AffiliateProductPlacement {
  export function getAllAffiliateProductPlacements(): AffiliateProductPlacement[] {
    return [
      AffiliateProductPlacement.BLOG,
      AffiliateProductPlacement.CREATOR_SUCCESS_EMAIL,
      AffiliateProductPlacement.CREATOR_SUCCESS_POPUP,
      AffiliateProductPlacement.ATTENDEES_SUCCESS_EMAIL,
      AffiliateProductPlacement.ATTENDEES_SUCCESS_POPUP,
      AffiliateProductPlacement.PRODUCTION_SECTION,
    ];
  }

  export function getDisplayName(placement: AffiliateProductPlacement): string {
    switch (placement) {
      case AffiliateProductPlacement.BLOG:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PLACEMENTS.BLOG';
      case AffiliateProductPlacement.CREATOR_SUCCESS_EMAIL:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PLACEMENTS.CREATOR_SUCCESS_EMAIL';
      case AffiliateProductPlacement.CREATOR_SUCCESS_POPUP:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PLACEMENTS.CREATOR_SUCCESS_POPUP';
      case AffiliateProductPlacement.ATTENDEES_SUCCESS_EMAIL:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PLACEMENTS.ATTENDEES_SUCCESS_EMAIL';
      case AffiliateProductPlacement.ATTENDEES_SUCCESS_POPUP:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PLACEMENTS.ATTENDEES_SUCCESS_POPUP';
      case AffiliateProductPlacement.PRODUCTION_SECTION:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PLACEMENTS.PRODUCTION_SECTION';
      default:
        return '';
    }
  }
}

<h1 mat-dialog-title class="title">{{ dialogTitle | translate }}</h1>
<div mat-dialog-content>
  <div class="image">
    <img *ngIf="dialogImage" [src]="dialogImage" alt="image"/>
  </div>

  <div class="content" *ngIf="dialogQuestion !== ''" [ngClass]="{'bold': dialogWarning !== ''}">
    {{ dialogQuestion | translate }}
  </div>

  <div class="content" *ngIf="dialogWarning !== ''">
    {{ dialogWarning | translate }}
  </div>

  <div class="content" *ngIf="dialogConfirmation !== ''">
    {{ dialogConfirmation | translate }}
  </div>

  <div class="columns-container">
    <div class="column content">
      <div>
        {{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_INFO.ID' | translate }}
      </div>

      <div *ngIf="dialogInformations.partner !== undefined">
        {{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_INFO.PARTNERS' | translate }}
      </div>

      @if (dialogInformations.performance !== undefined) {
        <div *ngIf="dialogInformations.performance.clicks !== undefined">
          {{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_INFO.PERFORMANCE' | translate }}
        </div>

        <div *ngIf="dialogInformations.performance.views !== undefined">
          {{ 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_INFO.VISITS' | translate }}:
        </div>

        <div *ngIf="dialogInformations.performance.uses !== undefined">
          {{ 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_INFO.USED' | translate }}
        </div>
      }

      <div *ngIf="dialogInformations.placements?.length > 0">
        {{ 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_INFO.PLACEMENTS' | translate }}
      </div>
    </div>

    <div class="column content">
      <div>
        {{ dialogInformations.id }}
      </div>

      <div *ngIf="dialogInformations.partner !== undefined">
        {{ dialogInformations.partner | translate }}
      </div>

      @if (dialogInformations.performance !== undefined) {
        <div *ngIf="dialogInformations.performance.clicks !== undefined">
          {{ dialogInformations.performance.clicks }}
          {{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_INFO.CLICKS' | translate }}
          ({{ 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.CUSTOM_DIALOG.DELETE_INFO.CTR' | translate }}
          {{ dialogInformations.performance.ctr }}%)
        </div>

        <div *ngIf="dialogInformations.performance.views !== undefined">
          {{ dialogInformations.performance.views }}
          {{ 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_INFO.VISITS' | translate }}
        </div>

        <div *ngIf="dialogInformations.performance.uses !== undefined">
          {{ dialogInformations.performance.uses }}
          ({{ 'APP.DESIGN_TEMPLATES.DELETE_DIALOG.DELETE_INFO.CR' | translate }}
          {{ dialogInformations.performance.utr }}%)
        </div>
      }

      <div *ngIf="dialogInformations.placements?.length > 0">
        <span *ngFor="let placement of dialogInformations.placements; let last = last">
        {{ getDisplayPlacementName(placement) | translate }}<span *ngIf="!last">, </span>
      </span>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="actions-container">
  <button
    (click)="cancel()"
    type="button"
    mat-raised-button
    class="actionButton btn-outline-secondary cancelButton"
  >
    <span class="actions">
      {{ dialogCancelButtonMessage | translate }}
    </span>
  </button>
  <button
    *ngIf="isDeleteDialog"
    (click)="delete()"
    type="button"
    mat-raised-button
    class="actionButton btn btn-warning deleteButton"
  >
    <span class="actions">
      {{ dialogDeleteButtonMessage | translate }}
    </span>
  </button>
</div>

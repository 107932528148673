import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { AffiliateProductPlacement } from '@models/affiliate-products/affiliate-product-placement.enum';
import { AffiliateProductPartner } from '@models/affiliate-products/affiliate-product-partner.enum';

@Component({
  selector: 'app-custom-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogTitle,
    MatDialogContent,
    TranslateModule,
    MatDialogActions,
    MatButtonModule,
  ],
  templateUrl: './custom-dialog.component.html',
  styleUrl: './custom-dialog.component.scss',
})
export class CustomDialogComponent {
  isDeleteDialog: boolean = false;
  dialogTitle: string = '';
  dialogImage: string = '';
  dialogConfirmation: string = '';
  dialogQuestion: string = '';
  dialogWarning: string = '';
  dialogInformations: any = undefined;
  dialogCancelButtonMessage: string = '';
  dialogDeleteButtonMessage: string = '';

  defaultDeleteButtonMessage =
    'APP.AFFILIATE_PRODUCTS_PAGE_LIST.ACTION_BUTTONS.CONFIRM_DELETE';

  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.isDeleteDialog = data.isDeleteDialog;
      this.dialogTitle = data.title;
      this.dialogImage = data.pictureUrl;
      this.dialogConfirmation = data.dialogConfirmation;
      this.dialogQuestion = data.question;
      this.dialogWarning = data.warning;
      this.dialogInformations = {
        id: data.id,
        partner: data.partner
          ? this.getDisplayPartnerName(data.partner)
          : undefined,
        performance: data.performance,
        placements: data.placements,
      };
      this.dialogCancelButtonMessage = data.cancelButtonMessage;
      this.dialogDeleteButtonMessage =
        data.deleteButtonMessage || this.defaultDeleteButtonMessage;
    }
  }

  getDisplayPlacementName(placement: AffiliateProductPlacement) {
    return AffiliateProductPlacement.getDisplayName(placement);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  delete(): void {
    this.dialogRef.close(true);
  }

  getDisplayPartnerName(partner: AffiliateProductPartner) {
    return AffiliateProductPartner.getDisplayName(partner);
  }
}
